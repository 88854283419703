<template>
	<div class="topNavCon" style="width:1200px; margin: 0 auto;">
		<div class="topNavCon_left">
			<div class="logoCon" style="justify-content:flex-start">
				<!-- <img src="../../assets/logo_en.png" height="40" v-if="'cn'==activeLang" /> -->
				<!-- <img src="../../assets/logo_en.png" height="60" /> -->
				<img src="../../assets/logo_en.png" width="150" />
			</div>
		</div>
		<div class="topNavCon_center">
			<div style="font-size: 16px; padding-top: 20px;">
				<span v-if="'cn'==activeLang">互易仓 - 后台管理系统</span>
				<span v-else>HYTX WMS - Management System</span>
			</div>
		</div>
		<div class="topNavCon_right">
			<div class="topHeaderRiCon">
				<div class="topHeaderLang" style="margin-top:15px; text-align: right;">
					<el-radio-group v-model="activeLang" size="small" @change="switchLang" style="">
						<el-radio-button :label="'cn'">中&nbsp;文</el-radio-button>
						<el-radio-button :label="'en'">EN</el-radio-button>
					</el-radio-group>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				activeLang: this.$i18n.locale,
			}
		},
		mounted() {},
		methods: {
			//切换语言
			switchLang(lang) {
				this.$i18n.locale = lang;
				this.$store.dispatch('UPDATE_LANG', lang);
				this.$message.success(this.$t('topBar.switchLangSuccess'));
				this.$alert(this.$t('tips.changeLangMsg'), this.$t('tips.tipsTitle'), {
					// confirmButtonText: this.$t('204ffab8a6e01870'),
					type:'warning',
					callback: action => {
						window.location.reload();
					}
				});
			},
		}
	};
</script>

<style scoped lang="less">

</style>